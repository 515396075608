var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--homepage_data-type-tabs mb-30 display-flex justify-content-center"
  }, [_c('div', {
    staticClass: "display-inline-flex align-items-start justify-content-center box-rounded overflow-hidden tw-w-full sm:tw-w-auto overflow-hidden data-type-tabs"
  }, _vm._l(_vm.dataTypeItems, function (item, index) {
    return _c('button', {
      key: `plain-type-item-${index}`,
      class: [_vm.buttonClasses, item.classes, {
        selected: item.value === _vm.selectedDataType
      }],
      attrs: {
        "data-testid": `${item.value}-tab`
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.changeSelectedDataType(item.value);
        }
      }
    }, [_c('span', {
      staticClass: "tw-relative tw-z-10"
    }, [_vm._v(_vm._s(_vm.$t(item.title)))])]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }