<template>
  <div
    class="tw-bg-cobranded_hero_bg tw-pt-14 sm:tw-pt-28 tw-bg-cover tw-bg-[center_right] sm:tw-bg-center tw-bg-no-repeat tw-bg-scroll tw-bg-origin-padding tw-relative"
    :style="{
      backgroundImage: `${heroCardBackgroundOverlay} url(${
        branding?.hero_image || require('~/assets/images/default-cobranded-hero.webp')
      })`,
    }"
  >
    <div class="hero-wrapper tw-relative">
      <div
        class="sm:tw-max-w-xl sm:tw-px-8 sm:tw-pt-16 sm:tw-rounded-t-lg"
        :style="{
          backgroundColor: heroCardBackroundColour,
          marginInlineStart: isMobile ? '0' : '20%',
          marginInlineEnd: isMobile ? '0' : 'auto',
        }"
      >
        <slot name="hero-title" />
        <div class="store-actions tw-relative tw-z-10 tw-bg-transparent tw-shadow-none tw-border-none">
          <div class="sm:tw-max-w-6xl tw-m-auto tw-w-full tw-grid sm:tw-justify-start sm:tw-px-4">
            <slot name="country-search" />

            <div class="tw-grid tw-bg-white sm:tw-bg-transparent sm:tw-justify-start tw-pt-4">
              <slot name="tabs" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    computed: {
      ...mapGetters({
        branding: 'coBranding/getBranding',
      }),

      brandRgbValue() {
        const [r, g, b] = this.branding?.background_color
          ?.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
          ?.substring(1)
          ?.match(/.{2}/g)
          ?.map((x) => parseInt(x, 16)) || [74, 74, 74]

        return `rgba(${r}, ${g}, ${b}, 0.7)`
      },

      heroCardBackroundColour() {
        return this.isMobile ? 'transparent' : this.brandRgbValue
      },

      heroCardBackgroundOverlay() {
        return this.isMobile ? `linear-gradient(0deg,${this.brandRgbValue}, ${this.brandRgbValue}),` : ''
      },

      isMobile() {
        return this.$store.getters.get_device_types.isMobile
      },
    },
  }
</script>
