var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--homepage_hero hero-container"
  }, [_c('h1', {
    staticClass: "hero-title",
    attrs: {
      "data-testid": "homepage-title"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.title)), _vm.seoTitle ? _c('span', {
    staticClass: "sr-only"
  }, [_vm._v(" | " + _vm._s(_vm.seoTitle))]) : _vm._e()]), _vm._v(" "), _c('p', {
    staticClass: "hero-description"
  }, [_vm._v("\n    " + _vm._s(_vm.description) + "\n  ")]), _vm._v(" "), _c('div', {
    staticClass: "hero-rating-wrapper"
  }, [_c('div', {
    on: {
      "click": function () {
        return _vm.handleStoreClick('PlayStore');
      }
    }
  }, [_c('a', {
    staticClass: "hero-rating-store-link",
    attrs: {
      "rel": "noopener",
      "href": _vm.adjustUrlWithImpact || 'https://play.google.com/store/apps/details?id=com.mobillium.airalo',
      "target": "_blank"
    }
  }, [_c('a-icon', {
    attrs: {
      "loader": "loader",
      "name": "icon-hero-rating-googleplay",
      "alternative-text": "Google play store icon",
      "width": "28",
      "height": "28",
      "loading": "eager"
    }
  })], 1)]), _vm._v(" "), _c('div', {
    on: {
      "click": function () {
        return _vm.handleStoreClick('AppStore');
      }
    }
  }, [_c('a', {
    staticClass: "hero-rating-store-link",
    attrs: {
      "rel": "noopener",
      "href": _vm.adjustUrlWithImpact || 'https://apps.apple.com/app/airalo-esim-store/id1475911720',
      "target": "_blank"
    }
  }, [_c('a-icon', {
    attrs: {
      "loader": "loader",
      "name": "icon-hero-rating-appstore",
      "alternative-text": "Apple store icon",
      "width": "28",
      "height": "28",
      "loading": "eager"
    }
  })], 1)]), _vm._v(" "), _c('raiting', {
    staticClass: "hero-rating",
    attrs: {
      "value": '4.5',
      "width": 22,
      "color": ['#FBB034', '#FFCC00'],
      "unrated-color": ['#DDDDDD', '#DDDDDD']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "hero-rating-review"
  }, [_vm._v(_vm._s(_vm.$t('components.hero.rating.review', {
    count: _vm.$t('components.hero.rating.review-count')
  })))])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }