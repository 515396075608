<template>
  <div class="c--modals_uae-restriction-modal modal-uae-restriction-close">
    <general-modal
      :modal-id="modalId"
      :hasCloseButton="true"
      :modalView="modalView"
      min-height="424"
      @onPressClose="onClose"
    >
      <div class="pt-30 px-20">
        <nuxt-img
          src="~/assets/images/png/united-arab-emirates.png"
          alt="UAE flag"
          :height="`54px`"
          :width="`72px`"
          class="w-auto"
          loading="lazy"
        />
        <h2 class="typo-h2 pt-15 tw-text-primary m-0">{{ $t('components.popup.uae-restriction-title') }}</h2>
        <div class="tw-whitespace-pre-line py-10 tw-text-secondary tw-text-15-21">
          {{ $t('components.popup.uae-restriction-description') }}
        </div>
        <div class="w-100 my-10 px-30 pb-30 pt-20">
          <button
            @click="onClose"
            type="button"
            class="button button-secondary block btn-primary-hv"
            data-testid="login-button"
          >
            {{ $t('components.tooltip.got-it-button') }}
          </button>
        </div>
      </div>
    </general-modal>
  </div>
</template>

<script>
  import GeneralModal from '@/components/modals/general-modal.vue'

  export default {
    name: 'uae-restriction-modal',
    components: { GeneralModal },
    props: {
      modalId: {
        type: String,
        required: false,
        default: 'uae-restriction-modal',
      },
      modalView: {
        type: Boolean,
        required: true,
      },
    },

    methods: {
      onClose() {
        this.$emit('closeUaeRestrictionModal')
      },
    },
  }
</script>

<style lang="scss" scoped></style>
