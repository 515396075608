<template>
  <div class="c--tabs_store-switcher-tabs route-switcher-tabs noselect">
    <ul>
      <li v-for="(tab, index) in tabs" :key="`route-switcher-tabs-${index}`" @click="routeSwitcherChange(tab)">
        <nuxt-link
          :to="localePath(tab.route)"
          :data-testid="`${tab.text}`"
          :class="{
            'active-tab': getActiveTab(tab),
            'sm:tw-bg-zinc-100 tw-rounded-t !tw-border sm:!tw-border-zinc-300': isCoBranded,
          }"
        >
          {{ $t(tab.text) }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      tabs: {
        type: Array,
        required: true,
      },
      propListType: {
        type: String,
        required: true,
      },
    },

    computed: {
      ...mapGetters({
        isCoBranded: 'coBranding/getIsCoBranded',
      }),

      listType() {
        return this.getListType()
      },
    },

    methods: {
      getListType() {
        if (this.propListType === 'regional-packages') return this.propListType
        if (this.$route.params.list) {
          if (this.$route.params.list === 'regional') return 'regional'
          if (this.$route.params.list === 'global') return 'global'
          return 'local-packages'
        } else {
          return 'local'
        }
      },

      getActiveTab(tab) {
        switch (tab.type) {
          case 'local':
            return this.listType === 'local' || this.listType === 'local-packages'
          case 'regional':
            return this.listType === 'regional' || this.listType === 'regional-packages'
          case 'global':
            return this.listType === 'global'
          default:
            return false
        }
      },

      routeSwitcherChange(tab) {
        // tab.type: local, regional, global
        let tabTitle = 'local_eSIMs_chosen'

        if (tab.type === 'regional') {
          tabTitle = 'regional_eSIMs_chosen'
        } else if (tab.type === 'global') {
          tabTitle = 'global_eSIM_chosen'
        }

        this.$utils.sendEvent('esimChosenEvent', tabTitle)
      },
    },
  }
</script>
