var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--skeleton_country-detail-item-skeleton sim-base-skeleton"
  }, [_c('SkeletonTheme', {
    attrs: {
      "color": "#F4F4F4",
      "highlight": "#fff"
    }
  }, [_c('Skeleton', {
    staticClass: "sim-base-skeleton-image"
  }), _vm._v(" "), _c('div', {
    staticClass: "sim-base-skeleton-header"
  }, [_c('div', {
    staticClass: "sim-base-skeleton-header-main"
  }, [_c('PuSkeleton')], 1), _vm._v(" "), _c('div', {
    staticClass: "sim-base-skeleton-header-sub"
  }, [_c('PuSkeleton')], 1)]), _vm._v(" "), _c('div', {
    staticClass: "sim-base-skeleton-row row-data"
  }, [_c('PuSkeleton', {
    staticClass: "sim-base-skeleton-row-col"
  }), _vm._v(" "), _c('PuSkeleton', {
    staticClass: "sim-base-skeleton-row-col"
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "sim-base-skeleton-row row-data"
  }, [_c('PuSkeleton', {
    staticClass: "sim-base-skeleton-row-col"
  }), _vm._v(" "), _c('PuSkeleton', {
    staticClass: "sim-base-skeleton-row-col"
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "sim-base-skeleton-bottom"
  }, [_c('PuSkeleton', {
    staticClass: "sim-base-skeleton-button"
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }