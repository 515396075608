<template>
  <div class="c--homepage_hero hero-container">
    <h1 data-testid="homepage-title" class="hero-title">
      {{ title }}<span v-if="seoTitle" class="sr-only"> | {{ seoTitle }}</span>
    </h1>
    <p class="hero-description">
      {{ description }}
    </p>

    <div class="hero-rating-wrapper">
      <div @click="() => handleStoreClick('PlayStore')">
        <a
          rel="noopener"
          :href="adjustUrlWithImpact || 'https://play.google.com/store/apps/details?id=com.mobillium.airalo'"
          target="_blank"
          class="hero-rating-store-link"
        >
          <a-icon
            loader="loader"
            name="icon-hero-rating-googleplay"
            alternative-text="Google play store icon"
            width="28"
            height="28"
            loading="eager"
          />
        </a>
      </div>

      <div @click="() => handleStoreClick('AppStore')">
        <a
          rel="noopener"
          :href="adjustUrlWithImpact || 'https://apps.apple.com/app/airalo-esim-store/id1475911720'"
          target="_blank"
          class="hero-rating-store-link"
        >
          <a-icon
            loader="loader"
            name="icon-hero-rating-appstore"
            alternative-text="Apple store icon"
            width="28"
            height="28"
            loading="eager"
          />
        </a>
      </div>

      <raiting
        :value="'4.5'"
        :width="22"
        :color="['#FBB034', '#FFCC00']"
        :unrated-color="['#DDDDDD', '#DDDDDD']"
        class="hero-rating"
      />
      <span class="hero-rating-review">{{
        $t('components.hero.rating.review', {
          count: $t('components.hero.rating.review-count'),
        })
      }}</span>
    </div>
  </div>
</template>

<script>
  import ClientSideCookies from 'js-cookie'

  import Raiting from '~/components/shared/raiting.vue'

  export default {
    components: {
      Raiting,
    },

    props: {
      title: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: false,
        default: '',
      },
      seoTitle: {
        type: String,
        required: false,
        default: '',
      },
    },

    data() {
      return {
        adjustUrlWithImpact: null,
      }
    },

    computed: {
      deviceType() {
        return this.$store.getters.get_device_types
      },
    },

    methods: {
      handleStoreClick(store) {
        this.$utils.sendEvent('storeClickEvent', { app_store: store })
      },
    },

    mounted() {
      /* istanbul ignore next */
      if (!this.deviceType.isDesktop) {
        const irClickId = this.$route.query.irclickid || ClientSideCookies.get('Airalo.irclickid')

        if (irClickId) {
          this.adjustUrlWithImpact = `https://app.adjust.com/87y8gpw?impactradius_click_id=${irClickId}`
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .hero {
    &-container {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 20px;

      @media screen and (min-width: 768px) {
        max-width: 540px;
        padding: 0;
      }
    }

    &-title {
      color: #4a4a4a;
      font-size: 1.6875rem;
      font-weight: 600;
      letter-spacing: -0.6px;
      line-height: 1.1935;
      text-align: center;
      margin: 0;

      @media screen and (min-width: 768px) {
        font-size: 1.9375rem;
      }

      & + .hero-description {
        margin-top: 10px;
      }
    }

    &-description {
      color: #8a8a8a;
      font-size: 0.9375rem;
      letter-spacing: 0;
      line-height: 1.4;
      margin: 0;
      text-align: center;

      @media screen and (max-width: 575px) {
        padding: 0 10px;
      }
    }

    &-rating-wrapper {
      width: 100%;
      margin: 10px auto 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .hero-rating {
        margin-left: 10px;
        margin-right: 6px;

        [dir='rtl'] & {
          margin-right: 10px;
          margin-left: 6px;
        }
      }

      .hero-rating-review {
        color: #8a8a8a;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
      }
    }
  }
</style>
