var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['c--homepage_country-list-item', 'country-item', {
      'active-item': _vm.isActiveItem
    }],
    attrs: {
      "data-testid": `${_vm.item.title}-title`
    },
    on: {
      "click": _vm.countryItemClick
    }
  }, [_c('div', [_c('nuxt-link', {
    attrs: {
      "to": _vm.localePath(_vm.generateRoute(_vm.item))
    }
  }, [_c('country-name', {
    attrs: {
      "countryFlagUrl": _vm.item.image.url,
      "countryName": _vm.item.title
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "airalo-icon-arrow-right tw-text-3 country-item-row-icon"
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }