var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "c--shared_star star d-inline-flex"
  }, [_c('svg', {
    attrs: {
      "width": "22px",
      "height": "22px",
      "viewBox": "0 0 22 22",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('title', [_vm._v("Star")]), _vm._v(" "), _c('defs', [_c('linearGradient', {
    attrs: {
      "x1": "0%",
      "y1": "50%",
      "x2": "100%",
      "y2": "50%",
      "id": `linearGradient-${_vm._uid}`
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": _vm.colorStart,
      "offset": "0%"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "stop-color": _vm.colorEnd,
      "offset": "100%"
    }
  })], 1), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M15.9307237,18.8545147 C16.2577563,18.610541 16.3288503,18.2062416 16.1368964,17.6555581 L14.6794687,13.3964739 L18.4119055,10.7685283 C18.8882355,10.4339357 19.0944082,10.0644898 18.9593296,9.67413184 C18.8242509,9.29074456 18.4474525,9.10253625 17.857372,9.10950693 L13.2789162,9.13738964 L11.8854731,4.85739339 C11.7077381,4.29973915 11.4162525,4 10.9967977,4 C10.5844523,4 10.2929668,4.29973915 10.1081223,4.85739339 L8.7146792,9.13738964 L4.13622337,9.10950693 C3.54614288,9.10253625 3.1764539,9.29074456 3.04137524,9.67413184 C2.90629657,10.0644898 3.10535987,10.4339357 3.5887993,10.7685283 L7.32123612,13.3964739 L5.85669901,17.6555581 C5.66474511,18.2062416 5.73583915,18.610541 6.06998111,18.8545147 C6.40412307,19.1054591 6.81646847,19.021811 7.29990791,18.6802477 L10.9967977,16.0174488 L14.6936875,18.6802477 C15.1771269,19.021811 15.5965817,19.1054591 15.9307237,18.8545147 Z",
      "id": "path-2"
    }
  }), _vm._v(" "), _c('filter', {
    attrs: {
      "x": "-312.5%",
      "y": "-266.7%",
      "width": "725.0%",
      "height": "766.7%",
      "filterUnits": "objectBoundingBox",
      "id": "filter-3"
    }
  }, [_c('feOffset', {
    attrs: {
      "dx": "0",
      "dy": "10",
      "in": "SourceAlpha",
      "result": "shadowOffsetOuter1"
    }
  }), _vm._v(" "), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "15",
      "in": "shadowOffsetOuter1",
      "result": "shadowBlurOuter1"
    }
  }), _vm._v(" "), _c('feColorMatrix', {
    attrs: {
      "values": "0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0",
      "type": "matrix",
      "in": "shadowBlurOuter1"
    }
  })], 1)], 1), _vm._v(" "), _c('g', {
    attrs: {
      "id": "Symbols",
      "stroke": "none",
      "stroke-width": "1",
      "fill": "none",
      "fill-rule": "evenodd"
    }
  }, [_c('g', {
    attrs: {
      "id": "􀋃"
    }
  }, [_c('use', {
    attrs: {
      "fill": "black",
      "fill-opacity": "1",
      "filter": "url(#filter-3)",
      "xlink:href": "#path-2"
    }
  }), _vm._v(" "), _c('use', {
    attrs: {
      "fill": `url(#linearGradient-${_vm._uid})`,
      "fill-rule": "evenodd",
      "xlink:href": "#path-2"
    }
  })])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }