var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--banners_referral referral-banner display-flex jutify-content-start align-items-center h-75 w-full box-rounded shadowed px-20 py-15 cursor-pointer",
    on: {
      "click": _vm.handleProfileBannerClick
    }
  }, [_vm._m(0), _vm._v(" "), _c('div', {
    class: ['referral-banner-content display-flex jutify-content-start align-items-center w-full', {
      'pl-20': _vm.$dir() === 'ltr',
      'pr-20': _vm.$dir() === 'rtl'
    }]
  }, [_c('p', {
    staticClass: "typo-p color-text-white mb-0"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('components.referral.banner.content.v2', {
    amount: _vm.giveAmount
  })) + "\n    ")]), _vm._v(" "), _c('span', {
    class: ['airalo-icon-arrow-right tw-text-white node-right-ico v-middle referral-arrow', {
      'ml-auto': _vm.$dir() === 'ltr',
      'rtl-transform-rotate-y mr-auto': _vm.$dir() === 'rtl'
    }]
  })])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "referral-banner-image"
  }, [_c('span', {
    staticClass: "airalo-icon-referral-1 tw-text-11 tw-text-white"
  })]);

}]

export { render, staticRenderFns }