var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--homepage_cobranding-hero tw-grid"
  }, [_c('div', {
    staticClass: "tw-max-w-6xl tw-m-auto tw-w-full tw-relative tw-px-4"
  }, [_c('div', {
    staticClass: "tw-max-w-md sm:tw-max-w-lg"
  }, [_c('h1', {
    staticClass: "tw-relative tw-text-left rtl:tw-text-right tw-text-white",
    attrs: {
      "data-testid": "homepage-title"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.title)), _vm.seoTitle ? _c('span', {
    staticClass: "sr-only"
  }, [_vm._v(" | " + _vm._s(_vm.seoTitle))]) : _vm._e()])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }