<template>
  <div class="c--shared_raiting raiting-box d-flex justify-content-start align-items-center">
    <div class="raiting-box-item relative" v-for="(item, index) in raitingValue" :key="index">
      <template v-if="item.type === 'rated'">
        <Star :id="`raiting-box-item-rated-${index}`" :color-start="starsColors.start" :color-end="starsColors.end" />
      </template>
      <template v-else-if="item.type === 'unrated'">
        <Star
          :id="`raiting-box-item-unrated-${index}`"
          :color-start="starsUnratedColors.start"
          :color-end="starsUnratedColors.end"
        />
      </template>
      <template v-else>
        <div class="position-absolute colored-star" :style="`width: ${getRatedStarWidth(item.value)}px;`">
          <Star :id="`raiting-box-item-rated-${index}`" :color-start="starsColors.start" :color-end="starsColors.end" />
        </div>
        <div class="relative d-flex">
          <Star
            :id="`raiting-box-item-unrated-${index}`"
            :color-start="starsUnratedColors.start"
            :color-end="starsUnratedColors.end"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import Star from '~/components/shared/star.vue'

  export default {
    components: {
      Star,
    },
    props: {
      value: {
        type: [Number, String], // 3,4,5 or '3.5', '4.5'
        required: true,
      },
      maxValue: {
        type: Number,
        required: false,
        default: 5,
      },
      width: {
        type: Number,
        required: false,
        default: 22,
      },
      color: {
        required: false,
        default: ['#FBB034', '#FFCC00'],
      },
      unratedColor: {
        required: false,
        default: ['#8A8A8A', '#8A8A8A'],
      },
    },
    computed: {
      raitingValue() {
        let currentValue = this.value

        if (this.value >= this.maxValue) currentValue = this.maxValue
        else if (this.value <= 0) currentValue = 0

        let remain =
          this.maxValue - currentValue > 1 ? (this.maxValue - currentValue) % 1 : this.maxValue - currentValue

        const ratedValue = remain < 1 ? currentValue - remain : currentValue

        const ratedArray = new Array(ratedValue).fill(1)
        const lastArray = []

        for (let index = 0; index < this.maxValue; index++) {
          const element = ratedArray[index]

          if (element && element === 1) {
            lastArray.push({
              type: 'rated',
              value: 1,
            })
          } else if (!element && remain > 0 && remain < 1) {
            lastArray.push({
              type: 'rated-unrated',
              value: remain,
            })
            remain = 0
          } else {
            lastArray.push({
              type: 'unrated',
              value: 0,
            })
          }
        }

        return lastArray
      },
      starsColors() {
        switch (typeof this.color) {
          case 'string':
            return {
              start: this.color,
              end: this.color,
            }
          case 'object':
            if (Array.isArray(this.color) && this.color.length > 1) {
              return {
                start: this.color[0],
                end: this.color[1],
              }
            } else if (Array.isArray(this.color) && this.color.length === 1) {
              return {
                start: this.color[0],
                end: this.color[0],
              }
            } else if (!Array.isArray(this.color) && this.color !== null && this.color.start && this.color.end) {
              return {
                start: this.color.start,
                end: this.color.end,
              }
            } else {
              return {
                start: '#FBB034',
                end: '#FFCC00',
              }
            }
          default:
            return {
              start: '#FBB034',
              end: '#FFCC00',
            }
        }
      },
      starsUnratedColors() {
        switch (typeof this.unratedColor) {
          case 'string':
            return {
              start: this.unratedColor,
              end: this.unratedColor,
            }
          case 'object':
            if (Array.isArray(this.unratedColor) && this.unratedColor.length > 1) {
              return {
                start: this.unratedColor[0],
                end: this.unratedColor[1],
              }
            } else if (Array.isArray(this.unratedColor) && this.unratedColor.length === 1) {
              return {
                start: this.unratedColor[0],
                end: this.unratedColor[0],
              }
            } else if (
              !Array.isArray(this.unratedColor) &&
              this.unratedColor !== null &&
              this.unratedColor.start &&
              this.unratedColor.end
            ) {
              return {
                start: this.unratedColor.start,
                end: this.unratedColor.end,
              }
            } else {
              return {
                start: '#8A8A8A',
                end: '#8A8A8A',
              }
            }
          default:
            return {
              start: '#8A8A8A',
              end: '#8A8A8A',
            }
        }
      },
    },

    methods: {
      getRatedStarWidth(val) {
        return this.width * val
      },
    },
  }
</script>

<style lang="scss" scoped>
  .raiting-box-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .colored-star {
    left: 0;
    top: 0;
    z-index: 9;
    overflow: hidden;

    [dir='rtl'] & {
      left: unset;
      right: 0;
    }
  }
</style>
