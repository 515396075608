var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--modals_uae-restriction-modal modal-uae-restriction-close"
  }, [_c('general-modal', {
    attrs: {
      "modal-id": _vm.modalId,
      "hasCloseButton": true,
      "modalView": _vm.modalView,
      "min-height": "424"
    },
    on: {
      "onPressClose": _vm.onClose
    }
  }, [_c('div', {
    staticClass: "pt-30 px-20"
  }, [_c('nuxt-img', {
    staticClass: "w-auto",
    attrs: {
      "src": require("assets/images/png/united-arab-emirates.png"),
      "alt": "UAE flag",
      "height": `54px`,
      "width": `72px`,
      "loading": "lazy"
    }
  }), _vm._v(" "), _c('h2', {
    staticClass: "typo-h2 pt-15 tw-text-primary m-0"
  }, [_vm._v(_vm._s(_vm.$t('components.popup.uae-restriction-title')))]), _vm._v(" "), _c('div', {
    staticClass: "tw-whitespace-pre-line py-10 tw-text-secondary tw-text-15-21"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('components.popup.uae-restriction-description')) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "w-100 my-10 px-30 pb-30 pt-20"
  }, [_c('button', {
    staticClass: "button button-secondary block btn-primary-hv",
    attrs: {
      "type": "button",
      "data-testid": "login-button"
    },
    on: {
      "click": _vm.onClose
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('components.tooltip.got-it-button')) + "\n        ")])])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }