var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--shared_country-name-in-flag d-flex justify-content-start align-items-center country-name",
    attrs: {
      "data-testid": `${_vm.countryName}-name`
    }
  }, [_c('div', {
    staticClass: "flag",
    class: {
      'country-list-item': _vm.countryFlagWidth === 37
    }
  }, [_c('img', {
    directives: [{
      name: "lazy-load",
      rawName: "v-lazy-load"
    }],
    attrs: {
      "src": "/assets/images/placeholders/homepage-sim-flag.png",
      "data-src": _vm.countryFlagUrl,
      "alt": _vm.countryName,
      "width": _vm.countryFlagWidth,
      "height": _vm.aspectRatioHeight
    }
  })]), _vm._v(" "), _c('p', [_vm._v("\n    " + _vm._s(_vm.countryName) + "\n  ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }