var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-white"
  }, [_c('div', {
    staticClass: "hero-wrapper tw-relative"
  }, [_vm._t("hero-title")], 2), _vm._v(" "), _c('div', {
    staticClass: "store-actions tw-relative tw-z-10"
  }, [_c('div', [_vm._t("country-search"), _vm._v(" "), _c('div', [_vm._t("tabs")], 2)], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }