var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--tabs_store-switcher-tabs route-switcher-tabs noselect"
  }, [_c('ul', _vm._l(_vm.tabs, function (tab, index) {
    return _c('li', {
      key: `route-switcher-tabs-${index}`,
      on: {
        "click": function ($event) {
          return _vm.routeSwitcherChange(tab);
        }
      }
    }, [_c('nuxt-link', {
      class: {
        'active-tab': _vm.getActiveTab(tab),
        'sm:tw-bg-zinc-100 tw-rounded-t !tw-border sm:!tw-border-zinc-300': _vm.isCoBranded
      },
      attrs: {
        "to": _vm.localePath(tab.route),
        "data-testid": `${tab.text}`
      }
    }, [_vm._v("\n        " + _vm._s(_vm.$t(tab.text)) + "\n      ")])], 1);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }