<template>
  <div
    class="c--banners_referral referral-banner display-flex jutify-content-start align-items-center h-75 w-full box-rounded shadowed px-20 py-15 cursor-pointer"
    @click="handleProfileBannerClick"
  >
    <div class="referral-banner-image">
      <span class="airalo-icon-referral-1 tw-text-11 tw-text-white"></span>
    </div>
    <div
      :class="[
        'referral-banner-content display-flex jutify-content-start align-items-center w-full',
        {
          'pl-20': $dir() === 'ltr',
          'pr-20': $dir() === 'rtl',
        },
      ]"
    >
      <p class="typo-p color-text-white mb-0">
        {{ $t('components.referral.banner.content.v2', { amount: giveAmount }) }}
      </p>
      <span
        :class="[
          'airalo-icon-arrow-right tw-text-white node-right-ico v-middle referral-arrow',
          {
            'ml-auto': $dir() === 'ltr',
            'rtl-transform-rotate-y mr-auto': $dir() === 'rtl',
          },
        ]"
      />
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      giveAmount() {
        return this.$store.getters['multiCurrency/get_referral_give_amount']
      },
    },

    methods: {
      handleProfileBannerClick() {
        this.$utils.sendEvent('referralBannerEvent', 'profile')

        this.$router.push(this.localePath('/profile/referral'))
      },
    },
  }
</script>

<style lang="scss" scoped>
  .referral-banner {
    background: linear-gradient(90deg, #ff5f6d 0%, #ffc371 100%);

    [dir='rtl'] & {
      background: linear-gradient(270deg, #ff5f6d 0%, #ffc371 100%);
    }
  }
</style>
