const fakeMethods = {
  pkgMxn_add___FAKE___PromotionData: (pkg) => {
    if (!(Array.isArray(pkg.promotions) && !!pkg.promotions.length) && !!(pkg.id % 2) && pkg.is_stock) {
      pkg.promotions = [
        {
          price: '1.50',
          discount: '70',
          end_date: '2023-10-31T00:00:00.000000Z',
        },
      ]
    }
  },
}

const packageMixin = (isSinglePackageUse, singlePackageVariableName) => {
  if (isSinglePackageUse) {
    //  A single package is handled in the component, so package can be accessed via this[singlePackageVariableName]
    //  As package might not be loaded yet, it should be asserted before using it
    return {
      computed: {
        //  Package Instance
        ___pkgInstance() {
          const path = singlePackageVariableName.split('.')

          if (path.length === 1) {
            return this[singlePackageVariableName]
          } else {
            return path.reduce((acum, currKey) => acum && acum[currKey], this)
          }
        },
        //  DVT
        pkgMxn_packageIsDVT() {
          return this.___pkgInstance?.operator?.plan_type === 'data-voice-text'
        },
        pkgMxn_packageIsTopup() {
          return this.___pkgInstance?.type === 'topup'
        },
        pkgMxn_packageHasData() {
          return !!this.___pkgInstance?.data
        },
        pkgMxn_packageHasUnlimitedData() {
          return this.___pkgInstance?.is_unlimited === true
        },
        pkgMxn_packageHasVoice() {
          return !!this.___pkgInstance?.voice
        },
        pkgMxn_packageHasUnlimitedVoice() {
          return this.___pkgInstance?.is_unlimited_voice === true
        },
        pkgMxn_packageHasText() {
          return !!this.___pkgInstance?.text
        },
        pkgMxn_packageHasUnlimitedText() {
          return this.___pkgInstance?.is_unlimited_text === true
        },
        pkgMxn_packageHasDVTData() {
          const hasData = this.pkgMxn_packageHasData
          const dataIsUnlimited = this.pkgMxn_packageHasUnlimitedData

          return hasData || dataIsUnlimited
        },
        pkgMxn_packageHasDVTVoice() {
          const isDVT = this.pkgMxn_packageIsDVT
          const isTopup = this.pkgMxn_packageIsTopup
          if (!isDVT && !isTopup) return false

          const hasVoice = this.pkgMxn_packageHasVoice
          const voiceIsUnlimited = this.pkgMxn_packageHasUnlimitedVoice
          return hasVoice || voiceIsUnlimited
        },
        pkgMxn_packageHasDVTText() {
          const isDVT = this.pkgMxn_packageIsDVT
          const isTopup = this.pkgMxn_packageIsTopup
          if (!isDVT && !isTopup) return false

          const hasText = this.pkgMxn_packageHasText
          const textIsUnlimited = this.pkgMxn_packageHasUnlimitedText
          return hasText || textIsUnlimited
        },

        //  Promotions
        pkgMxn_packageHasPromotion() {
          if (this.packagePromoted === undefined || this.packagePromoted === true) {
            return Array.isArray(this.___pkgInstance?.promotions) && this.___pkgInstance?.promotions.length > 0
          } else {
            return false
          }
        },
        pkgMxn_packageCurrentPrice() {
          return this.pkgMxn_packageHasPromotion
            ? this.pkgMxn_packagePromotedPrice
            : this.pkgMxn_packageNonPromotedPrice
        },
        pkgMxn_packagePromotedPrice() {
          return this.___pkgInstance.promotions[0].price
        },
        pkgMxn_packageNonPromotedPrice() {
          return this.___pkgInstance.price
        },
        pkgMxn_packagePromotionDiscount() {
          return this.pkgMxn_packageHasPromotion ? this.___pkgInstance.promotions[0].discount : 0
        },
        pkgMxn_packagePromotionEndDateIso() {
          return this.pkgMxn_packageHasPromotion ? this.___pkgInstance.promotions[0].end_date : ''
        },
      },

      methods: {
        pkgMxn_addPromotionData(promotion) {
          this.___pkgInstance.promotions = [promotion]
        },

        ...fakeMethods,
      },
    }
  } else {
    //  Multiple packages are handled in the component, so package has to be passed as parameter to methods
    //  As packages are already loaded before calling methods, there's no need to assert pkg parameter
    return {
      methods: {
        //  DVT
        pkgMxn_packageIsDVT(pkg) {
          return pkg.operator?.plan_type === 'data-voice-text'
        },
        pkgMxn_packageIsTopup(pkg) {
          return pkg?.type === 'topup'
        },
        pkgMxn_packageHasData(pkg) {
          return !!pkg.data
        },
        pkgMxn_packageHasUnlimitedData(pkg) {
          return pkg.is_unlimited === true
        },
        pkgMxn_packageHasVoice(pkg) {
          return !!pkg.voice
        },
        pkgMxn_packageHasUnlimitedVoice(pkg) {
          return pkg.is_unlimited_voice === true
        },
        pkgMxn_packageHasText(pkg) {
          return !!pkg.text
        },
        pkgMxn_packageHasUnlimitedText(pkg) {
          return pkg.is_unlimited_text === true
        },
        pkgMxn_packageHasDVTData(pkg) {
          const hasData = this.pkgMxn_packageHasData(pkg)
          const dataIsUnlimited = this.pkgMxn_packageHasUnlimitedData(pkg)

          return hasData || dataIsUnlimited
        },
        pkgMxn_packageHasDVTVoice(pkg) {
          const isDVT = this.pkgMxn_packageIsDVT(pkg)
          const isTopup = this.pkgMxn_packageIsTopup(pkg)
          if (!isDVT && !isTopup) return false

          const hasVoice = this.pkgMxn_packageHasVoice(pkg)
          const voiceIsUnlimited = this.pkgMxn_packageHasUnlimitedVoice(pkg)
          return hasVoice || voiceIsUnlimited
        },
        pkgMxn_packageHasDVTText(pkg) {
          const isDVT = this.pkgMxn_packageIsDVT(pkg)
          const isTopup = this.pkgMxn_packageIsTopup(pkg)
          if (!isDVT && !isTopup) return false

          const hasText = this.pkgMxn_packageHasText(pkg)
          const textIsUnlimited = this.pkgMxn_packageHasUnlimitedText(pkg)
          return hasText || textIsUnlimited
        },

        //  Promotions
        pkgMxn_packageHasPromotion(pkg) {
          return Array.isArray(pkg.promotions) && pkg.promotions.length > 0
        },
        pkgMxn_packageCurrentPrice(pkg) {
          return this.pkgMxn_packageHasPromotion(pkg)
            ? this.pkgMxn_packagePromotedPrice(pkg)
            : this.pkgMxn_packageNonPromotedPrice(pkg)
        },
        pkgMxn_packagePromotedPrice(pkg) {
          return pkg?.promotions[0].price
        },
        pkgMxn_packageNonPromotedPrice(pkg) {
          return pkg?.price
        },
        pkgMxn_packagePromotionDiscount(pkg) {
          return this.pkgMxn_packageHasPromotion(pkg) ? pkg.promotions[0].discount : 0
        },
        pkgMxn_packagePromotionEndDateIso(pkg) {
          return this.pkgMxn_packageHasPromotion(pkg) ? pkg.promotions[0].end_date : ''
        },
        pkgMxn_addPromotionData(pkg, promotion) {
          pkg.promotions = [promotion]
        },

        ...fakeMethods,
      },
    }
  }
}

export default packageMixin
