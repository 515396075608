var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--skeleton_country-list-item-skeleton country-item-card"
  }, [_c('PuSkeleton', {
    staticClass: "country-item-img"
  }), _vm._v(" "), _c('PuSkeleton', {
    staticClass: "country-item-title"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }