var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--shared_raiting raiting-box d-flex justify-content-start align-items-center"
  }, _vm._l(_vm.raitingValue, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "raiting-box-item relative"
    }, [item.type === 'rated' ? [_c('Star', {
      attrs: {
        "id": `raiting-box-item-rated-${index}`,
        "color-start": _vm.starsColors.start,
        "color-end": _vm.starsColors.end
      }
    })] : item.type === 'unrated' ? [_c('Star', {
      attrs: {
        "id": `raiting-box-item-unrated-${index}`,
        "color-start": _vm.starsUnratedColors.start,
        "color-end": _vm.starsUnratedColors.end
      }
    })] : [_c('div', {
      staticClass: "position-absolute colored-star",
      style: `width: ${_vm.getRatedStarWidth(item.value)}px;`
    }, [_c('Star', {
      attrs: {
        "id": `raiting-box-item-rated-${index}`,
        "color-start": _vm.starsColors.start,
        "color-end": _vm.starsColors.end
      }
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "relative d-flex"
    }, [_c('Star', {
      attrs: {
        "id": `raiting-box-item-unrated-${index}`,
        "color-start": _vm.starsUnratedColors.start,
        "color-end": _vm.starsUnratedColors.end
      }
    })], 1)]], 2);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }