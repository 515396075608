<template>
  <div
    :class="['c--homepage_country-list-item', 'country-item', { 'active-item': isActiveItem }]"
    :data-testid="`${item.title}-title`"
    @click="countryItemClick"
  >
    <div>
      <nuxt-link :to="localePath(generateRoute(item))">
        <country-name :countryFlagUrl="item.image.url" :countryName="item.title" />
        <span class="airalo-icon-arrow-right tw-text-3 country-item-row-icon"></span>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
  import CountryName from '~/components/shared/country-name-in-flag'

  export default {
    components: {
      CountryName,
    },

    props: {
      item: {
        type: Object,
        required: true,
      },
      itemIndex: {
        type: Number,
        required: true,
      },
      listType: {
        type: String,
        required: true,
      },
      showDataVoiceText: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    computed: {
      isActiveItem() {
        return this.$route.params.list === this.item.slug
      },
    },

    methods: {
      generateRoute(item) {
        let listParamValue

        if (this.$route.params.list === item.slug) {
          if (this.listType === 'regional-packages') {
            listParamValue = 'regional'
          }
        } else {
          listParamValue = item.slug
        }

        if (listParamValue) {
          return {
            name: 'index-list',
            params: {
              list: listParamValue,
            },
          }
        } else {
          return {
            name: 'index',
          }
        }
      },
      countryItemClick() {
        this.$utils.sendEvent('viewItemListEvent', {
          item_list_id: this.item.slug,
          item_list_name: this.item.title,
        })
      },
      onChangePlainType(payload) {
        this.$emit('onChangePlainType', payload)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .country-item {
    border-radius: 7px;
    border: 1px solid transparent;
    background-color: #ffffff;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;

    &.active-item {
      border-color: #4a4a4a;

      a span {
        transform: rotate(-90deg);
        color: #4a4a4a;

        [dir='rtl'] & {
          transform: rotate(270deg);
        }
      }
    }

    a {
      &,
      &:focus {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 13px 20px 12px;
        text-decoration: none;
        transition: all 0.25s;
        min-height: 55px;
      }

      &:focus {
        outline: none;
      }

      span {
        display: flex;
        transform: rotate(90deg);
        transition: all 0.2s;
        color: #dddddd;
      }

      &,
      &:hover,
      &:focus {
        color: #4a4a4a;
      }
    }
  }
</style>
