var render = function render(){
  var _vm$branding;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tw-bg-cobranded_hero_bg tw-pt-14 sm:tw-pt-28 tw-bg-cover tw-bg-[center_right] sm:tw-bg-center tw-bg-no-repeat tw-bg-scroll tw-bg-origin-padding tw-relative",
    style: {
      backgroundImage: `${_vm.heroCardBackgroundOverlay} url(${((_vm$branding = _vm.branding) === null || _vm$branding === void 0 ? void 0 : _vm$branding.hero_image) || require('~/assets/images/default-cobranded-hero.webp')})`
    }
  }, [_c('div', {
    staticClass: "hero-wrapper tw-relative"
  }, [_c('div', {
    staticClass: "sm:tw-max-w-xl sm:tw-px-8 sm:tw-pt-16 sm:tw-rounded-t-lg",
    style: {
      backgroundColor: _vm.heroCardBackroundColour,
      marginInlineStart: _vm.isMobile ? '0' : '20%',
      marginInlineEnd: _vm.isMobile ? '0' : 'auto'
    }
  }, [_vm._t("hero-title"), _vm._v(" "), _c('div', {
    staticClass: "store-actions tw-relative tw-z-10 tw-bg-transparent tw-shadow-none tw-border-none"
  }, [_c('div', {
    staticClass: "sm:tw-max-w-6xl tw-m-auto tw-w-full tw-grid sm:tw-justify-start sm:tw-px-4"
  }, [_vm._t("country-search"), _vm._v(" "), _c('div', {
    staticClass: "tw-grid tw-bg-white sm:tw-bg-transparent sm:tw-justify-start tw-pt-4"
  }, [_vm._t("tabs")], 2)], 2)])], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }