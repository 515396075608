<template>
  <div
    class="c--homepage_country-search country-search-component"
    :class="searchContainerClasses"
    ref="searchContainer"
  >
    <!-- Search Input -->
    <div class="inp-search-container">
      <input
        ref="inpSearch"
        class="inp-search mobile-w-100"
        data-testid="search-input"
        type="text"
        :disabled="isSearchSimsDisabled"
        v-model="searchStr"
        @focus="onInputSearchFocus"
        @focusout="onInputSearchFocusOut"
        :placeholder="$t('placeholders.search.bar.store')"
      />
      <span
        class="clear-close airalo-icon-close-round-filled"
        data-testid="clear-search-field-buton"
        v-if="searchStr"
        @click="onClearSearchStrClicked"
      />
      <b-spinner
        small
        v-if="isSearchSimsDisabled"
        class="tw-absolute tw-top-4 tw-left-4"
        data-testid="search-hydration-loader"
      />
      <span v-else class="airalo-icon-search" />
    </div>

    <!-- Search Results: Loading -->
    <div
      v-if="shouldShowCountriesListLoader"
      class="countries-list position-absolute"
      @click="onSearchResultsListClick"
    >
      <div class="countries-list-loader">
        <b-spinner small />
      </div>
    </div>

    <!-- Search Results -->
    <ul v-if="shouldShowSearchResultsList" class="countries-list position-absolute" @click="onSearchResultsListClick">
      <!-- Countries -->
      <li v-if="searchResultsContainCountries" class="countries-list-segment-container">
        <div class="countries-search-segment">
          <p>{{ $t('scenes.country.search.local-section') }}</p>
        </div>
      </li>
      <li
        v-for="(country, index) in searchResults.countries"
        :key="`country-list-item-${country.slug}-${index}`"
        @click.prevent="search(country, country.slug, 'local')"
      >
        <span class="country-name" :data-testid="`${country.title}-name`">{{ country.title }}</span>
        <img
          class="country-flag"
          :data-testid="`${country.title}-flag`"
          :src="getCountryFlag(country.image)"
          :alt="country.title"
          data-not-lazy
        />
      </li>
      <!-- Regions -->
      <li v-if="searchResultsContainRegions" class="countries-list-segment-container">
        <div class="countries-search-segment">
          <p>{{ $t('scenes.country.search.regional-section') }}</p>
        </div>
      </li>
      <li
        v-for="(country, index) in searchResults.regions"
        :key="`country-list-item-${country.slug}-${index}`"
        @click.prevent="search(country, country.slug, 'region')"
      >
        <span :data-testid="`${country.title}-name`">{{ country.title }}</span>
        <img class="country-flag" :src="getCountryFlag(country.image)" :alt="country.title" data-not-lazy />
      </li>
      <!-- Global -->
      <li v-if="searchResultsContainGlobals" class="countries-list-segment-container">
        <div class="countries-search-segment segment-global">
          <p>{{ $t('scenes.country.search.global-section') }}</p>
        </div>
      </li>
      <li
        v-for="(country, index) in searchResults.globals"
        :key="`country-list-item-${country.slug}-${index}`"
        @click.prevent="search(country, `global`, 'global')"
      >
        <span class="country-name" :data-testid="`${country.title}-name`">{{ country.title }}</span>
        <img class="country-flag" :src="getCountryFlag(country.image)" :alt="country.title" data-not-lazy />
      </li>
    </ul>

    <!-- Search Results: No Results -->
    <ul
      v-else-if="shouldShowNoCountriesFound"
      class="countries-list no-countries position-absolute"
      @click="onSearchResultsListClick"
    >
      <li>
        <span class="country-name" data-testid="no-countries-found-result">{{
          $t('global.no-country-available')
        }}</span>
      </li>
    </ul>

    <!-- Search Results: Recently Visited -->
    <ul
      v-if="shouldShowRecentlyVisitedCountriesList"
      class="countries-list position-absolute"
      @click="onSearchResultsListClick"
    >
      <li class="countries-list-segment-container">
        <div class="countries-list-recently d-flex align-items-center justify-content-between">
          <h5 data-testid="recently-visited">{{ $t('scenes.country.search.recent-section') }}</h5>
          <span data-testid="clear-recently-visited-button" @click.prevent="clearRecentlyVisited">{{
            $t('global.clear')
          }}</span>
        </div>
      </li>
      <li
        v-for="(country, index) in recentlyVisited"
        :key="`country-list-item-${country.slug}-${index}`"
        @click.prevent="search(country, country.slug, 'recent')"
      >
        <span class="country-name" :data-testid="`${country.title}-name`">{{ country.title }}</span>
        <img class="country-flag" :src="getCountryFlag(country.image)" :alt="country.title" data-not-lazy />
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      listType: {
        type: String,
        required: false,
        default: 'local',
      },
    },

    data() {
      return {
        areSearchResultsEmpty: false,
        containerFirstTop: 0,
        inputHasFocus: false,
        inputNeedsFocus: false,
        isFixed: false,
        isFocusReg: false,
        isSearching: false,
        isSearchSimsDisabled: true,
        recentlyVisited: null,
        searchResults: null,
        searchStr: '',
        searchTimeoutInstance: null,
      }
    },

    computed: {
      bannerView() {
        return this.$store.getters.get_banner_view
      },

      searchContainerClasses() {
        return {
          'search-container': true,
          'position-relative': true,
          'fixed-search': this.isFixed,
        }
      },

      searchResultsContainCountries() {
        return this.searchResults?.countries?.length
      },
      searchResultsContainGlobals() {
        return this.searchResults?.globals?.length
      },
      searchResultsContainRegions() {
        return this.searchResults?.regions?.length
      },

      shouldShowCountriesListLoader() {
        return this.inputHasFocus && this.isSearching && this.searchStr.length >= this.minimumSearchSymbols
      },
      shouldShowNoCountriesFound() {
        return this.inputHasFocus && this.isFocusReg && this.areSearchResultsEmpty
      },
      shouldShowRecentlyVisitedCountriesList() {
        return this.inputHasFocus && this.recentlyVisited?.length && !this.searchStr.length
      },
      shouldShowSearchResultsList() {
        return (
          this.inputHasFocus &&
          this.searchResults &&
          this.searchStr.length >= this.minimumSearchSymbols &&
          !this.areSearchResultsEmpty
        )
      },
      minimumSearchSymbols() {
        const japaneseRegex = /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/
        const chineseRegex = /[\u3400-\u9FBF]/
        const koreanRegex = /[\u1100-\u11FF\u3130-\u318F\uA960-\uA97F\uAC00-\uD7AF\uD7B0-\uD7FF]/g
        if (
          this.searchStr.match(chineseRegex) ||
          this.searchStr.match(koreanRegex) ||
          this.searchStr.match(japaneseRegex)
        ) {
          return 1
        }

        return 2
      },
    },

    watch: {
      searchStr(val) {
        if (!this.$store.state.searched) {
          this.searchList('keyup')
        }
      },

      bannerView(val) {
        if (!val) {
          this.addFixed()
        }
      },
    },

    methods: {
      getCountryFlag(image) {
        return image?.url || '/assets/images/eSIM-Card-Visual-Placeholder@2x.png'
      },
      getTranslatedItemType(itemType) {
        if (itemType === 'local') return 'country'
        if (itemType === 'global') return 'region'
        return itemType
      },

      async setRecentlyVisited(item) {
        const addItem = {
          id: item.id,
          type: this.getTranslatedItemType(item.type),
        }

        if (localStorage?.getItem('sessionuuid')) {
          const uuid = localStorage.getItem('sessionuuid')

          try {
            await this.$axios.post('/v2/store/recently', addItem, {
              headers: {
                'X-OS': 'web',
                'X-Device-Uuid': uuid,
              },
            })
            return true
          } catch (error) {
            return false
          }
        }
      },

      async onInputSearchFocus() {
        this.recentlyVisited = await this.getAllRecentlyVisited()
        this.inputHasFocus = true
      },

      onInputSearchFocusOut() {
        setTimeout(() => {
          if (this.inputNeedsFocus) {
            this.$refs.inpSearch.focus()
          } else {
            this.recentlyVisited = null
            this.inputHasFocus = false
          }
          this.inputNeedsFocus = false
        }, 200)
      },

      onSearchResultsListClick() {
        this.inputNeedsFocus = true
      },

      async clearRecentlyVisited() {
        this.isSearchSimsDisabled = true
        this.recentlyVisited = null

        if (localStorage?.getItem('sessionuuid')) {
          const uuid = localStorage.getItem('sessionuuid')

          try {
            await this.$axios.delete('/v2/store/recently', {
              headers: {
                'X-OS': 'web',
                'X-Device-Uuid': uuid,
              },
            })
            return true
          } catch (error) {
            return false
          } finally {
            setTimeout(() => {
              this.isSearchSimsDisabled = false
            }, 100)
          }
        } else {
          setTimeout(() => {
            this.isSearchSimsDisabled = false
          }, 100)
        }
      },

      checkSearchResultsEmpty(data) {
        if (!data) return

        return Object.keys(data).every((item) => data[item].length === 0)
      },

      async searchList(type) {
        setTimeout(() => {
          if (this.searchStr.length >= this.minimumSearchSymbols && (type === 'focus' ? !!this.isFocusReg : true)) {
            this.searchResults = null
            this.areSearchResultsEmpty = false
            this.isSearching = true

            try {
              if (this.searchTimeoutInstance !== null) {
                clearTimeout(this.searchTimeoutInstance)
              }

              this.searchTimeoutInstance = setTimeout(() => {
                ;(async () => {
                  const searched = await this.$axios.get(`v2/store/search/?q=${this.searchStr}`)

                  this.$utils.sendEvent('countrySearchedEvent', this.searchStr)
                  this.$utils.sendEvent('searchEvent', {
                    esim_location: '',
                    search_term: this.searchStr,
                  })

                  this.searchResults = searched.data
                  if (this.checkSearchResultsEmpty(this.searchResults)) {
                    this.isFocusReg = true
                    this.areSearchResultsEmpty = true
                  }
                  this.isSearching = false
                })()
              }, 1000)
            } catch (error) {
              this.searchResults = null
              this.isSearching = false
              this.areSearchResultsEmpty = true
            }
          } else {
            this.isFocusReg = false
            this.searchResults = null
            this.areSearchResultsEmpty = false
          }
        }, 100)
      },

      async onClearSearchStrClicked(isParent) {
        this.$store.commit('set_searched', false)
        this.searchResults = null
        this.searchStr = ''

        this.isFocusReg = false
        this.areSearchResultsEmpty = false

        if ((this.$route.params.list?.length > 0 && !isParent) || this.listType === 'local-packages') {
          this.$emit('countrySearchClearAfter')
          this.$router.push(this.localePath('/'))
        }

        this.inputNeedsFocus = true
        this.$refs.inpSearch.focus()
      },

      search(item, slug, type) {
        let searchType = type === 'recent' ? item.type : type
        const searchItem =
          item.slug === 'world' ? { ...item, slug: 'global', type: searchType } : { ...item, type: searchType }

        const itemSlug = slug === 'world' ? 'global' : slug

        this.$utils.sendEvent('searchEvent', searchItem)

        if (!this.$route.params.list || this.$route.params.list !== slug) {
          if (searchType === 'local') {
            this.searchStr = searchItem.title
          } else {
            this.searchStr = ''
          }

          this.$store.commit('set_searched', true)
          this.setRecentlyVisited(searchItem)

          this.$router.push(
            this.localePath({
              name: 'index-list',
              params: {
                list: itemSlug,
              },
            })
          )

          this.searchResults = null
          this.isSearching = false
        } else {
          if (searchType === 'local') {
            this.searchStr = searchItem.title
            this.$store.commit('set_searched', true)
            setTimeout(() => {
              this.$store.commit('set_searched', false)
            }, 200)
          } else {
            this.searchStr = ''
          }

          this.searchResults = null
          this.isSearching = false
        }

        const channel = type === 'recent' ? 'recent' : 'search'
        this.$utils.sendEvent('searchClickEvent', {
          keyword: searchItem.title,
          channel,
          searchType,
        })

        this.inputHasFocus = false
      },

      async getAllRecentlyVisited() {
        if (localStorage?.getItem('sessionuuid')) {
          const uuid = localStorage.getItem('sessionuuid')

          try {
            const response = await this.$axios.get('/v2/store/recently', {
              headers: {
                'X-OS': 'web',
                'X-Device-Uuid': uuid,
              },
            })
            if (response?.data?.length > 0) {
              return this.getUniqueListBy(response.data, 'id')
            } else {
              return []
            }
          } catch (error) {
            return []
          }
        }
      },

      async sendLSToAPI() {
        const uuid = localStorage?.getItem('sessionuuid')

        if (uuid) {
          this.$axios.defaults.headers.common['X-Device-Uuid'] = uuid
          let recentlyData = localStorage.getItem('searchRecentlyVisited') || []

          if (recentlyData.length > 0) {
            try {
              recentlyData = JSON.parse(recentlyData).map((v) => {
                return {
                  id: v.id,
                  type: this.getTranslatedItemType(v.type),
                }
              })

              if (localStorage.getItem('addedBulkSRV') === 'added') {
                return
              }

              const response = await this.$axios.post(
                '/v2/store/recently/all',
                {
                  d: recentlyData,
                },
                {
                  headers: {
                    'X-OS': 'web',
                    'X-Device-Uuid': uuid,
                  },
                }
              )

              if (response.status === 201) {
                localStorage.removeItem('searchRecentlyVisited')
                localStorage.setItem('addedBulkSRV', 'added')
              }
            } catch (error) {
              console.error('sendLSToAPI', { error })
            }
          }
        }
      },

      addFixed() {
        const container = this.$refs.searchContainer
        const containerGBCR = container.getBoundingClientRect()

        let bannerWrapper = document.querySelector('.banner-wrapper')

        let topSpace = 0
        let topSpaceMin = 25

        if (bannerWrapper) {
          topSpace = bannerWrapper.getBoundingClientRect().height
          topSpaceMin = topSpace
        }

        if (containerGBCR.top <= topSpaceMin && !this.isFixed) {
          this.isFixed = true
          if (bannerWrapper) {
            container.style.top = `${topSpace}px`
          }
        } else if (
          (containerGBCR.top === 0 || (bannerWrapper && containerGBCR.top === topSpace)) &&
          this.isFixed &&
          document.querySelector('.store-actions').getBoundingClientRect().top > topSpaceMin
        ) {
          this.isFixed = false
          container.style = ''
        } else if (this.isFixed && !bannerWrapper && containerGBCR.top > 0) {
          container.style = ''
        }
      },

      getUniqueListBy(arr, key) {
        return [...new Map(arr.map((item) => [item[key], item])).values()]
      },
    },

    beforeDestroy() {
      window.removeEventListener('scroll', this.addFixed)
    },

    mounted() {
      if (this.$route.query.q?.length > 0) {
        this.searchStr = this.$route.query.q

        if (this.$route.query.cb === 'sitelinks_searchbox') {
          this.$utils.sendEvent('googleSearchActionEvent', this.$route.query.q)
        }

        setTimeout(() => {
          this.$scrollTo(`.inp-search-container`, {
            offset: -60,
            duration: 200,
          })
        }, 250)
      }

      if (process.browser) {
        if (window.innerWidth <= 1024) {
          window.removeEventListener('scroll', this.addFixed)
          window.addEventListener('scroll', this.addFixed)
        } else {
          if (this.isFixed) {
            this.isFixed = false
          }
        }
      }
      this.isSearchSimsDisabled = false
      this.sendLSToAPI()
    },
  }
</script>

<style lang="scss" scoped>
  .inp-search {
    width: 100%;
    font-size: 0.9375rem !important;
    border-radius: 7px;
    padding: 12px 50px 13px !important;
    line-height: 1;

    &:placeholder-shown {
      text-overflow: ellipsis;
    }
  }

  .search-container {
    max-width: 540px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 2.5rem;
    transition: width 0.2s;
    transform-origin: center;

    @media (max-width: 575px) {
      max-width: unset;

      .countries-list {
        max-height: calc(50vh - 82px);
        overflow-y: auto;
        @media screen and (orientation: landscape) {
          max-height: calc(70vh - 82px);
        }
      }
    }

    @media only screen and (max-width: 768px) {
      padding: 0 20px;
      margin-bottom: 20px;
    }

    &.fixed-search {
      @media (max-width: 575px) {
        position: fixed !important;
        top: 0;
        left: 0;
        width: 100% !important;
        background-color: #fff;
        max-width: unset;
        margin: 0;
        z-index: 20;
        padding: 20px 20px !important;
      }

      @media only screen and (min-width: 576px) and (max-width: 1024px) {
        position: fixed !important;
        top: 0;
        left: 0;
        width: 100% !important;
        background-color: #fff;
        padding: 26px 30px !important;
        max-width: unset;
        margin: 0;
        z-index: 20;
      }

      .countries-list {
        @media (max-width: 575px) {
          width: calc(100% - 40px) !important;
          left: 20px;
          top: 82px;
        }

        @media only screen and (min-width: 576px) and (max-width: 1024px) {
          width: calc(100% - 60px) !important;
          left: 30px;
          top: 88px;
        }
      }
    }
  }

  .clear-close {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    @media only screen and (max-width: 768px) {
      right: 20px;
    }

    [dir='rtl'] & {
      left: 20px;
      right: unset;

      @media only screen and (max-width: 768px) {
        left: 20px;
        right: unset;
      }
    }
  }

  .country-flag {
    width: 37px;
    height: 28px;
    vertical-align: middle;
    object-fit: contain;
  }

  .country-name {
    display: inline-block;
    font-weight: 450;
    font-size: 15px;
    color: #4a4a4a;
    letter-spacing: 0;
    line-height: 1;
  }

  .countries-list {
    background: #fafafa;
    border: 1px solid #dddddd;
    border-radius: 7px;
    overflow: hidden;
    width: 100%;
    z-index: 20;
    top: 62px;

    li {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: 11px 20px;

      &:hover {
        background-color: #eeeeee;
      }

      &.countries-list-segment-container {
        cursor: default;
        display: block;
        padding: 0;
        &:hover {
          background-color: transparent;
        }
      }
    }

    .countries-search-segment {
      background-color: #fafafa;
      border-radius: 4px;
      border: 1px solid #dddddd;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
      margin: 9px 20px 9px 19px;
      text-align: left;
      width: max-content;

      max-width: 30%;
      @media only screen and (max-width: 768px) {
        max-width: 50%;
      }

      &:first-child {
        margin-top: 18px;
      }

      p {
        color: #4a4a4a;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        margin: 0 auto;
        padding: 7px 20px 6px;
      }

      &.segment-global {
        background: linear-gradient(90deg, #07053f 0%, #0988a3 100%);
        [dir='rtl'] & {
          background: linear-gradient(270deg, #07053f 0%, #0988a3 100%);
        }

        p {
          color: #fff;
        }
      }
    }

    &.no-countries {
      li {
        cursor: default;
        padding: 20px 18px 18px 20px;

        &:hover {
          background-color: transparent;
        }
      }

      .country-name {
        color: #8a8a8a;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
        width: 100%;
      }
    }
  }

  .countries-list-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
  }

  .countries-list-recently {
    margin: 19px 20px 10px 20px;

    h5 {
      color: #4a4a4a;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 0;
    }

    span {
      cursor: pointer;
      color: #4a4a4a;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 1.5px;
      line-height: 10px;
    }
  }
</style>
