var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "searchContainer",
    staticClass: "c--homepage_country-search country-search-component",
    class: _vm.searchContainerClasses
  }, [_c('div', {
    staticClass: "inp-search-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchStr,
      expression: "searchStr"
    }],
    ref: "inpSearch",
    staticClass: "inp-search mobile-w-100",
    attrs: {
      "data-testid": "search-input",
      "type": "text",
      "disabled": _vm.isSearchSimsDisabled,
      "placeholder": _vm.$t('placeholders.search.bar.store')
    },
    domProps: {
      "value": _vm.searchStr
    },
    on: {
      "focus": _vm.onInputSearchFocus,
      "focusout": _vm.onInputSearchFocusOut,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchStr = $event.target.value;
      }
    }
  }), _vm._v(" "), _vm.searchStr ? _c('span', {
    staticClass: "clear-close airalo-icon-close-round-filled",
    attrs: {
      "data-testid": "clear-search-field-buton"
    },
    on: {
      "click": _vm.onClearSearchStrClicked
    }
  }) : _vm._e(), _vm._v(" "), _vm.isSearchSimsDisabled ? _c('b-spinner', {
    staticClass: "tw-absolute tw-top-4 tw-left-4",
    attrs: {
      "small": "",
      "data-testid": "search-hydration-loader"
    }
  }) : _c('span', {
    staticClass: "airalo-icon-search"
  })], 1), _vm._v(" "), _vm.shouldShowCountriesListLoader ? _c('div', {
    staticClass: "countries-list position-absolute",
    on: {
      "click": _vm.onSearchResultsListClick
    }
  }, [_c('div', {
    staticClass: "countries-list-loader"
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _vm.shouldShowSearchResultsList ? _c('ul', {
    staticClass: "countries-list position-absolute",
    on: {
      "click": _vm.onSearchResultsListClick
    }
  }, [_vm.searchResultsContainCountries ? _c('li', {
    staticClass: "countries-list-segment-container"
  }, [_c('div', {
    staticClass: "countries-search-segment"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t('scenes.country.search.local-section')))])])]) : _vm._e(), _vm._v(" "), _vm._l(_vm.searchResults.countries, function (country, index) {
    return _c('li', {
      key: `country-list-item-${country.slug}-${index}`,
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.search(country, country.slug, 'local');
        }
      }
    }, [_c('span', {
      staticClass: "country-name",
      attrs: {
        "data-testid": `${country.title}-name`
      }
    }, [_vm._v(_vm._s(country.title))]), _vm._v(" "), _c('img', {
      staticClass: "country-flag",
      attrs: {
        "data-testid": `${country.title}-flag`,
        "src": _vm.getCountryFlag(country.image),
        "alt": country.title,
        "data-not-lazy": ""
      }
    })]);
  }), _vm._v(" "), _vm.searchResultsContainRegions ? _c('li', {
    staticClass: "countries-list-segment-container"
  }, [_c('div', {
    staticClass: "countries-search-segment"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t('scenes.country.search.regional-section')))])])]) : _vm._e(), _vm._v(" "), _vm._l(_vm.searchResults.regions, function (country, index) {
    return _c('li', {
      key: `country-list-item-${country.slug}-${index}`,
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.search(country, country.slug, 'region');
        }
      }
    }, [_c('span', {
      attrs: {
        "data-testid": `${country.title}-name`
      }
    }, [_vm._v(_vm._s(country.title))]), _vm._v(" "), _c('img', {
      staticClass: "country-flag",
      attrs: {
        "src": _vm.getCountryFlag(country.image),
        "alt": country.title,
        "data-not-lazy": ""
      }
    })]);
  }), _vm._v(" "), _vm.searchResultsContainGlobals ? _c('li', {
    staticClass: "countries-list-segment-container"
  }, [_c('div', {
    staticClass: "countries-search-segment segment-global"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t('scenes.country.search.global-section')))])])]) : _vm._e(), _vm._v(" "), _vm._l(_vm.searchResults.globals, function (country, index) {
    return _c('li', {
      key: `country-list-item-${country.slug}-${index}`,
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.search(country, `global`, 'global');
        }
      }
    }, [_c('span', {
      staticClass: "country-name",
      attrs: {
        "data-testid": `${country.title}-name`
      }
    }, [_vm._v(_vm._s(country.title))]), _vm._v(" "), _c('img', {
      staticClass: "country-flag",
      attrs: {
        "src": _vm.getCountryFlag(country.image),
        "alt": country.title,
        "data-not-lazy": ""
      }
    })]);
  })], 2) : _vm.shouldShowNoCountriesFound ? _c('ul', {
    staticClass: "countries-list no-countries position-absolute",
    on: {
      "click": _vm.onSearchResultsListClick
    }
  }, [_c('li', [_c('span', {
    staticClass: "country-name",
    attrs: {
      "data-testid": "no-countries-found-result"
    }
  }, [_vm._v(_vm._s(_vm.$t('global.no-country-available')))])])]) : _vm._e(), _vm._v(" "), _vm.shouldShowRecentlyVisitedCountriesList ? _c('ul', {
    staticClass: "countries-list position-absolute",
    on: {
      "click": _vm.onSearchResultsListClick
    }
  }, [_c('li', {
    staticClass: "countries-list-segment-container"
  }, [_c('div', {
    staticClass: "countries-list-recently d-flex align-items-center justify-content-between"
  }, [_c('h5', {
    attrs: {
      "data-testid": "recently-visited"
    }
  }, [_vm._v(_vm._s(_vm.$t('scenes.country.search.recent-section')))]), _vm._v(" "), _c('span', {
    attrs: {
      "data-testid": "clear-recently-visited-button"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clearRecentlyVisited.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('global.clear')))])])]), _vm._v(" "), _vm._l(_vm.recentlyVisited, function (country, index) {
    return _c('li', {
      key: `country-list-item-${country.slug}-${index}`,
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.search(country, country.slug, 'recent');
        }
      }
    }, [_c('span', {
      staticClass: "country-name",
      attrs: {
        "data-testid": `${country.title}-name`
      }
    }, [_vm._v(_vm._s(country.title))]), _vm._v(" "), _c('img', {
      staticClass: "country-flag",
      attrs: {
        "src": _vm.getCountryFlag(country.image),
        "alt": country.title,
        "data-not-lazy": ""
      }
    })]);
  })], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }