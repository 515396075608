<template>
  <div class="c--skeleton_country-detail-item-skeleton sim-base-skeleton">
    <SkeletonTheme color="#F4F4F4" highlight="#fff">
      <Skeleton class="sim-base-skeleton-image" />
      <div class="sim-base-skeleton-header">
        <div class="sim-base-skeleton-header-main"><PuSkeleton /></div>
        <div class="sim-base-skeleton-header-sub"><PuSkeleton /></div>
      </div>
      <div class="sim-base-skeleton-row row-data">
        <PuSkeleton class="sim-base-skeleton-row-col" />
        <PuSkeleton class="sim-base-skeleton-row-col" />
      </div>
      <div class="sim-base-skeleton-row row-data">
        <PuSkeleton class="sim-base-skeleton-row-col" />
        <PuSkeleton class="sim-base-skeleton-row-col" />
      </div>
      <div class="sim-base-skeleton-bottom">
        <PuSkeleton class="sim-base-skeleton-button" />
      </div>
    </SkeletonTheme>
  </div>
</template>

<script>
  import { Skeleton, SkeletonTheme } from 'vue-loading-skeleton'

  export default {
    components: { Skeleton, SkeletonTheme },
  }
</script>

<style lang="scss" scoped>
  .sim-base-skeleton {
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0 0 0;
    position: relative;
    -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.2);
    vertical-align: top;
    border-radius: 7px;

    &-header {
      padding: 19px 25px 25px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      min-height: 58px;

      &-main {
        width: 50%;
      }

      &-sub {
        width: 35%;
      }
    }

    &-image {
      position: absolute;
      display: flex;
      top: -20px;
      right: 20px;
      width: 140px;
      height: 87px;
      border-radius: 12px;
      background-image: url('/assets/images/placeholders/sim-operator-small-light.svg');
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);

      ::v-deep {
        .pu-skeleton {
          height: 88px;
          border-radius: 12px;
        }
      }
    }

    &-row {
      padding: 10px 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 58px;

      &-col {
        width: 45%;
      }
    }

    &-bottom {
      padding: 20px 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 58px;
    }

    &-button {
      width: 100%;
      height: 38px;
      border-radius: 7px;
    }
  }
</style>
